import { useState } from 'react';
import { Customer, Order, OrderSource, OrderStatus } from '../../API';
import SimpleModal from '../helper-components/SimpleModal';
import { Alert, Button, TextField } from '@aws-amplify/ui-react';
import { AiFillEye } from 'react-icons/ai';
import { useAppDispatch } from '../../store/hooks';
import { wooGetOrderAsync } from '../../store/woocommerceSlice';
import { emailRegex, numbersChar } from '../../utils/Constants';
import { HiPencil } from 'react-icons/hi';
import { setItemsInfo, updateOrderAsync } from '../../store/itemsSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { FaHeadset, FaSync } from 'react-icons/fa';
import { createDeskTicketAsync, setDeskError } from '../../store/deskSlice';

function AddressPopup({ order }: { order: Order | null }) {
    const dispatch = useAppDispatch()
    const { usergroups } = useSelector((state: RootState) => state.userState)
    const { isLoading } = useSelector((state: RootState) => state.deskState)
    const userEmail = useSelector((state: RootState) => state.userState.email)
    const [open, setOpen] = useState(false)
    const [edit, setEdit] = useState(false)
    const [email, SetEmail] = useState(order?.customer.email)
    const [phone, setPhone] = useState(order?.customer.phone)
    const [firstName, setFirstName] = useState(order?.customer.first_name)
    const [lastName, setLastName] = useState(order?.customer.last_name)
    const [street, SetStreet] = useState(order?.customer.street)
    const [houseNo, SetHouseNo] = useState(order?.customer.house_number)
    const [city, SetCity] = useState(order?.customer.city)
    const [zip, SetZip] = useState(order?.customer.zip)
    const [company, SetCompany] = useState(order?.customer.company)
    const [add, SetAdd] = useState(order?.customer.add)
    const [dhl_lockerId, setDhl_lockerId] = useState(order?.customer?.dhl_lockerId)
    const [dhl_postNumber, setDhl_postNumber] = useState(order?.customer?.dhl_postNumber)
    const [errorMessage, setErrorMessage] = useState('')
    const [infoMessage, setInfoMessage] = useState('')


    async function getCustomerAdress(order: Order) {
        const orderInfos = await dispatch(wooGetOrderAsync(order.orderNumber))
        const shippingInfos = orderInfos.payload.shipping
        const firstNumber = shippingInfos.address_1.match(/\d/) && shippingInfos.address_1.match(/\d/).length > 0 ? shippingInfos.address_1.match(/\d/).index : undefined
        const street = shippingInfos.address_1.slice(0, firstNumber)
        const house_number = firstNumber ? shippingInfos.address_1.slice(firstNumber) : ''

        setFirstName(shippingInfos.first_name)
        setLastName(shippingInfos.last_name)
        SetStreet(street)
        SetHouseNo(house_number)
        setDhl_lockerId(shippingInfos.dhl_lockerId)
        setDhl_postNumber(shippingInfos.dhl_postNumber)
        SetZip(shippingInfos.postcode)
        SetCity(shippingInfos.city)
        SetCompany(shippingInfos.company)
        SetAdd(shippingInfos.address_2)
        SetEmail(orderInfos.payload.billing.email)
        setPhone(shippingInfos.phone)
        return { first_name: shippingInfos.first_name, last_name: shippingInfos.last_name, street, house_number, zip: shippingInfos.postcode, city: shippingInfos.city, company: shippingInfos.company, add: shippingInfos.address_2, email: orderInfos.payload.billing.email, phone: shippingInfos.phone, country: shippingInfos.country }
    }

    async function syncWooAddress() {
        if (!order) return
        const address = await getCustomerAdress(order)
        await saveWooAddress(address as Customer)
    }

    async function saveWooAddress(address: Customer) {
        if (!order) return
        if (!firstName || !lastName) {
            return setErrorMessage('Vorname und Nachname dürfen nicht leer sein')
        }
        const variables = {
            input: {
                id: order.id,
                customer: {
                    first_name: address.first_name,
                    last_name: address.last_name,
                    street: address.street,
                    house_number: address.house_number,
                    city: address.city,
                    zip: address.zip,
                    company: address.company || '',
                    add: address.add || '',
                    email: address.email,
                    phone: address.phone || '',
                    country: address.country || ''
                }
            }
        }
        const updateOrderAction = await dispatch(updateOrderAsync(variables))
        if (updateOrderAction.meta.requestStatus === 'fulfilled') {
            setInfoMessage('Adresse wurde erfolgreich synchronisiert')
        }
    }

    function close() {
        setOpen(false)
        setErrorMessage('')
        setItemsInfo('')
        setInfoMessage('')
    }

    function cancel() {
        setFirstName(order?.customer.first_name)
        setLastName(order?.customer.last_name)
        SetStreet(order?.customer.street)
        SetHouseNo(order?.customer.house_number)
        SetCity(order?.customer.city)
        SetZip(order?.customer.zip)
        SetCompany(order?.customer.company)
        setEdit(false)
        setErrorMessage('')
    }

    async function copyAdress() {
        const clipboardText = `
        E-Mail: ${ email }
        Telefon: ${ phone }
        Name: ${ firstName } ${ lastName } ${ company } ${ add }
        Straße + Nr.: ${ street } ${ houseNo }
        PLZ + Ort: ${ zip } ${ city }
        `

        await navigator.clipboard.writeText(clipboardText)
        setOpen(false)
        alert('Adresse wurde in die Zwischenablage kopiert')
    }

    async function saveChangedAdress() {
        setErrorMessage('')
        if (!order) return
        if (!firstName || !lastName) {
            return setErrorMessage('Vorname und Nachname dürfen nicht leer sein')
        }
        const variables = {
            input: {
                id: order.id,
                customer: {
                    first_name: firstName,
                    last_name: lastName,
                    street: street || '',
                    house_number: houseNo || '',
                    city: city || '',
                    zip: zip || '',
                    company: company || '',
                    add: add || '',
                    email: email || '',
                    dhl_lockerId: dhl_lockerId || '',
                    dhl_postNumber: dhl_postNumber || '',
                    // if this is not set the country will be undefined but it is not possible to change the country in the popup
                    country: order.customer.country
                }
            }
        }
        const updateOrderAction = await dispatch(updateOrderAsync(variables))
        if (updateOrderAction.meta.requestStatus === 'fulfilled') {
            // if (!order.source || order.source === OrderSource.woocommerce) {
            //     // email is only saved in billing adress
            //     // TODO we don't use this because taxes of the fees disappear when updating the order
            //     // this does not happen for newer orders because we made fees taxable but old orders will be effected and I did not find out how I can update the old orders
            //     let streetAndHouseNo = street ? street : ''
            //     if (houseNo) streetAndHouseNo += ' ' + houseNo

            //     let shipping = {
            //         first_name: firstName,
            //         last_name: lastName,
            //         company: company,
            //         address_1: streetAndHouseNo,
            //         address_2: add, // additional address info
            //         postcode: zip,
            //         city: city,
            //     }
            //     console.log('shipping', shipping)
            //     for (let key in shipping) {
            //         // @ts-ignore
            //         if (!shipping[key] || shipping[key] === undefined || shipping[key] === '' || shipping[key] === null) {
            //             // @ts-ignore
            //             delete shipping[key];
            //         }
            //     }
            //     dispatch(wooUpdateOrderAsync({ data: { shipping }, orderNumber: order.orderNumber }))
            // }
            dispatch(setItemsInfo('Adresse wurde erfolgreich geändert'))
        }
        setEdit(false)
    }

    async function createDeskTicket() {
        if (!order) return
        if (!firstName || !lastName || !email) {
            dispatch(setDeskError('Es kann kein Ticket erstellt werden, weil Daten fehlen, überprüfe die Adresse und E-Mail-Adresse.'))
            close()
            return
        }
        const data = {
            orderNumber: order.orderNumber,
            firstName: firstName,
            lastName: lastName,
            email: email,
            user: userEmail,
        }
        const createTicketAction = await dispatch(createDeskTicketAsync(data))
        if (createTicketAction.type === 'desk/createDeskTicket/fulfilled') {
            // @ts-ignore
            const ticketId = createTicketAction.payload.response.ticketId
            const url = `https://desk.zoho.eu/agent/nipsild/service/tickets/edit/${ ticketId }`
            // detail url: `https://desk.zoho.eu/agent/nipsild/service/tickets/details/${ ticketId }`
            window.open(url, '_blank')
        }
        // close address popup
        close()
    }

    if (edit) return (
        <div>
            <SimpleModal dismissAction={cancel} confirmAction={saveChangedAdress} open={edit} confirmBtnDisabled={!firstName || !lastName || !email} style={{ width: '60vw' }}>
                {errorMessage && <div>
                    <Alert
                        variation='error'
                        isDismissible={false}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined} >
                        {errorMessage}
                    </Alert>
                    <div style={{ height: '1em' }} />
                </div>}
                <div style={{ display: 'grid', gap: '0.5em' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }} >E-Mail</div>
                        <div style={{ flex: 2 }}>
                            <TextField label={'email'} defaultValue={email || ''} labelHidden={true}
                                onChange={(e: any) => { SetEmail(e.target.value) }}
                                hasError={email && !emailRegex.test(email) ? true : false}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }} >Telefon</div>
                        <div style={{ flex: 2 }}>
                            <TextField label={'phone'} defaultValue={phone || ''} labelHidden={true}
                                onChange={(e: any) => { setPhone(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>Vorname</div>
                        <div style={{ flex: 2 }}>
                            <TextField label={'firstName'} defaultValue={firstName} labelHidden={true}
                                onChange={(e: any) => { setFirstName(e.target.value); if (lastName) setErrorMessage('') }}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>Nachname</div>
                        <div style={{ flex: 2 }}>
                            <TextField label={'lastName'} defaultValue={lastName} labelHidden={true}
                                onChange={(e: any) => { setLastName(e.target.value); if (firstName) setErrorMessage('') }}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }} >Firma</div>
                        <div style={{ flex: 2 }}>
                            <TextField label={'company'} defaultValue={company || ''} labelHidden={true}
                                onChange={(e: any) => { SetCompany(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }} >Adresszusatz</div>
                        <div style={{ flex: 2 }}>
                            <TextField label={'additional'} defaultValue={add || ''} labelHidden={true}
                                onChange={(e: any) => { SetAdd(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>
                            Straße + Nr.</div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                            <div style={{ flex: 1 }}>
                                <TextField label={'street'} defaultValue={street || ''} labelHidden={true}
                                    onChange={(e: any) => { SetStreet(e.target.value) }}
                                />
                            </div>
                            <div style={{ width: 2 }} />
                            <div style={{ flex: 1 }}>
                                <TextField label={'houseNo'} defaultValue={houseNo || ''} labelHidden={true}
                                    onChange={(e: any) => { SetHouseNo(e.target.value) }}
                                />
                            </div>
                        </div>
                    </div>
                    {order && order.source !== OrderSource.woocommerce && <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }} >Packstation</div>
                        <div style={{ flex: 2 }}>
                            <TextField label={'lockerId'} defaultValue={dhl_lockerId || ''} labelHidden={true}
                                onChange={(e: any) => { setDhl_lockerId(e.target.value) }}
                            />
                        </div>
                    </div>}
                    {order && order.source !== OrderSource.woocommerce && <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }} >Postnummer</div>
                        <div style={{ flex: 2 }}>
                            <TextField label={'postNUmber'} defaultValue={dhl_postNumber || ''} labelHidden={true}
                                onChange={(e: any) => { setDhl_postNumber(e.target.value) }}
                            />
                        </div>
                    </div>}
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>
                            PLZ + Ort</div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                            <div style={{ flex: 1 }}>
                                <TextField label={'postcode'} defaultValue={zip || ''} labelHidden={true}
                                    onChange={(e: any) => { SetZip(e.target.value) }}
                                    hasError={zip && !numbersChar.test(zip) ? true : false}
                                />
                            </div>
                            <div style={{ width: 2 }} />
                            <div style={{ flex: 1 }}>
                                <TextField label={'city'} defaultValue={city || ''} labelHidden={true}
                                    onChange={(e: any) => { SetCity(e.target.value) }}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </SimpleModal>
        </div>
    )

    const finishedOrder = order?.status === OrderStatus.finished || order?.status === OrderStatus.cancelled

    return (
        <div>
            <Button
                id='show-address-button'
                style={{ backgroundColor: 'transparent', padding: '0', marginLeft: '0.3em' }}
                size={'small'}
                variation='link'
                onClick={() => { setOpen(true); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <AiFillEye />
            </Button>
            <SimpleModal dismissAction={close} open={open} okBtnText='Kopieren' cancelBtnText='Schließen' confirmAction={copyAdress}>
                {infoMessage && <Alert
                    fontSize={'small'}
                    style={{ marginBottom: '1em' }}
                    variation='info' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                >
                    {infoMessage}
                </Alert>}
                <div style={{ textAlign: 'right' }}>
                    {usergroups.includes('managers') &&
                        <Button
                            variation='link'
                            style={{ backgroundColor: 'transparent', padding: '0', paddingRight: '0.5em' }}
                            placeholder={undefined}
                            onClick={createDeskTicket}
                            isLoading={isLoading}
                            size='small' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <FaHeadset />
                        </Button>}
                    {usergroups.includes('managers') && order && !finishedOrder && (order.source && order.source !== OrderSource.woocommerce && order.source !== OrderSource.rekla) &&
                        <Button
                            id='edit-address-button'
                            style={{ backgroundColor: 'transparent', padding: '0' }}
                            size={'small'}
                            variation='link'
                            onClick={() => { setEdit(true); setOpen(false); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <HiPencil />
                        </Button>}
                    {order && (!order.source || order.source === OrderSource.woocommerce || order.source === OrderSource.rekla) &&
                        <Button
                            id='edit-address-button'
                            style={{ backgroundColor: 'transparent', padding: '0' }}
                            size={'small'}
                            variation='link'
                            onClick={() => { syncWooAddress(); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <FaSync />
                        </Button>
                    }
                </div>
                <div style={{ display: 'grid', gap: '0.5em' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', flex: 1 }} >E-Mail:</div>
                        <div style={{ flex: 2 }}>{email}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', flex: 1 }} >Telefon:</div>
                        <div style={{ flex: 2 }}>{phone}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '5em', flex: 1 }}>Vorname:</div>
                        <div style={{ flex: 2 }}>{firstName}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', flex: 1 }}>Nachname:</div>
                        <div style={{ flex: 2 }}>{lastName}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', flex: 1 }} >Firma:</div>
                        <div style={{ flex: 2 }}>{company}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', flex: 1 }} >Adresszusatz:</div>
                        <div style={{ flex: 2 }}>{add}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '5em', flex: 1 }}>
                            Straße + Nr.:</div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                            <div style={{ flex: 2 }}>{street} {houseNo}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '5em', flex: 1 }}>
                            Packstation:</div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                            <div style={{ flex: 2 }}>{dhl_lockerId}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '5em', flex: 1 }}>
                            Postnummer:</div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                            <div style={{ flex: 2 }}>{dhl_postNumber}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', flex: 1 }}>
                            PLZ + Ort:</div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                            <div style={{ flex: 2 }}>{zip} {city}</div>
                        </div>
                    </div>
                </div>
            </SimpleModal>
        </div>
    )
}

export default AddressPopup;