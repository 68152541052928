/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createOrder = /* GraphQL */ `mutation CreateOrder(
  $input: CreateOrderInput!
  $condition: ModelOrderConditionInput
) {
  createOrder(input: $input, condition: $condition) {
    id
    typename
    orderNumber
    date_created
    date_paid
    total
    cart_tax
    total_tax
    discount_total
    discount_tax
    shipping_total
    shipping_tax
    fees {
      name
      amount
      total
      total_tax
      __typename
    }
    weight
    customer {
      first_name
      last_name
      dhl_postNumber
      dhl_lockerId
      street
      house_number
      zip
      city
      country
      email
      phone
      company
      add
      __typename
    }
    shipping_method
    delivery_date
    status
    state
    source
    in_progressAt
    finishedAt
    updatedAt
    createdAt
    retoure_shipping_costs
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderMutationVariables,
  APITypes.CreateOrderMutation
>;
export const updateOrder = /* GraphQL */ `mutation UpdateOrder(
  $input: UpdateOrderInput!
  $condition: ModelOrderConditionInput
) {
  updateOrder(input: $input, condition: $condition) {
    id
    typename
    orderNumber
    date_created
    date_paid
    total
    cart_tax
    total_tax
    discount_total
    discount_tax
    shipping_total
    shipping_tax
    fees {
      name
      amount
      total
      total_tax
      __typename
    }
    weight
    customer {
      first_name
      last_name
      dhl_postNumber
      dhl_lockerId
      street
      house_number
      zip
      city
      country
      email
      phone
      company
      add
      __typename
    }
    shipping_method
    delivery_date
    status
    state
    source
    in_progressAt
    finishedAt
    updatedAt
    createdAt
    retoure_shipping_costs
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderMutationVariables,
  APITypes.UpdateOrderMutation
>;
export const deleteOrder = /* GraphQL */ `mutation DeleteOrder(
  $input: DeleteOrderInput!
  $condition: ModelOrderConditionInput
) {
  deleteOrder(input: $input, condition: $condition) {
    id
    typename
    orderNumber
    date_created
    date_paid
    total
    cart_tax
    total_tax
    discount_total
    discount_tax
    shipping_total
    shipping_tax
    fees {
      name
      amount
      total
      total_tax
      __typename
    }
    weight
    customer {
      first_name
      last_name
      dhl_postNumber
      dhl_lockerId
      street
      house_number
      zip
      city
      country
      email
      phone
      company
      add
      __typename
    }
    shipping_method
    delivery_date
    status
    state
    source
    in_progressAt
    finishedAt
    updatedAt
    createdAt
    retoure_shipping_costs
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderMutationVariables,
  APITypes.DeleteOrderMutation
>;
export const createProduct = /* GraphQL */ `mutation CreateProduct(
  $input: CreateProductInput!
  $condition: ModelProductConditionInput
) {
  createProduct(input: $input, condition: $condition) {
    id
    position
    typename
    name
    sku
    product_id
    variation_id
    quantity
    length
    width
    height
    area
    weight
    categories
    variations
    options
    extraOptions {
      name
      value
      imagesp
      price
      __typename
    }
    tasks
    currentTask
    contractor_currentTask
    productContractorId
    contractor {
      id
      number
      name
      billing_rate
      billing_max
      pickupAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      returnAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    status
    contractor_status
    shipping_label_printed
    orderNumber
    order {
      id
      typename
      orderNumber
      date_created
      date_paid
      total
      cart_tax
      total_tax
      discount_total
      discount_tax
      shipping_total
      shipping_tax
      fees {
        name
        amount
        total
        total_tax
        __typename
      }
      weight
      customer {
        first_name
        last_name
        dhl_postNumber
        dhl_lockerId
        street
        house_number
        zip
        city
        country
        email
        phone
        company
        add
        __typename
      }
      shipping_method
      delivery_date
      status
      state
      source
      in_progressAt
      finishedAt
      updatedAt
      createdAt
      retoure_shipping_costs
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productOrderId
    backoffice_note
    total_before_discount
    total_net_price
    total_tax
    nipsild_fixed_costs
    delivery_time
    delivery_date
    shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    tour {
      id
      typename
      date
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      deliveryNotificationSentTo
      info
      driver
      calculated_shipping_costs
      createdAt
      updatedAt
      __typename
    }
    declinedTourDates
    billed_amount
    customer_request_approved
    in_progressAt
    ready_to_packAt
    finishedAt
    updatedAt
    billedAt
    recievedReturnAt
    reklaOrderNumber
    retoure_type
    retoure_reason
    retoure_manufacturing_fee
    retoure_refund_amount
    retoure_refund_contractors_share
    retoure_shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    createdAt
    orderProductsId
    tourProductsId
    productShipmentId
    productRetoure_shipmentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductMutationVariables,
  APITypes.CreateProductMutation
>;
export const updateProduct = /* GraphQL */ `mutation UpdateProduct(
  $input: UpdateProductInput!
  $condition: ModelProductConditionInput
) {
  updateProduct(input: $input, condition: $condition) {
    id
    position
    typename
    name
    sku
    product_id
    variation_id
    quantity
    length
    width
    height
    area
    weight
    categories
    variations
    options
    extraOptions {
      name
      value
      imagesp
      price
      __typename
    }
    tasks
    currentTask
    contractor_currentTask
    productContractorId
    contractor {
      id
      number
      name
      billing_rate
      billing_max
      pickupAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      returnAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    status
    contractor_status
    shipping_label_printed
    orderNumber
    order {
      id
      typename
      orderNumber
      date_created
      date_paid
      total
      cart_tax
      total_tax
      discount_total
      discount_tax
      shipping_total
      shipping_tax
      fees {
        name
        amount
        total
        total_tax
        __typename
      }
      weight
      customer {
        first_name
        last_name
        dhl_postNumber
        dhl_lockerId
        street
        house_number
        zip
        city
        country
        email
        phone
        company
        add
        __typename
      }
      shipping_method
      delivery_date
      status
      state
      source
      in_progressAt
      finishedAt
      updatedAt
      createdAt
      retoure_shipping_costs
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productOrderId
    backoffice_note
    total_before_discount
    total_net_price
    total_tax
    nipsild_fixed_costs
    delivery_time
    delivery_date
    shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    tour {
      id
      typename
      date
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      deliveryNotificationSentTo
      info
      driver
      calculated_shipping_costs
      createdAt
      updatedAt
      __typename
    }
    declinedTourDates
    billed_amount
    customer_request_approved
    in_progressAt
    ready_to_packAt
    finishedAt
    updatedAt
    billedAt
    recievedReturnAt
    reklaOrderNumber
    retoure_type
    retoure_reason
    retoure_manufacturing_fee
    retoure_refund_amount
    retoure_refund_contractors_share
    retoure_shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    createdAt
    orderProductsId
    tourProductsId
    productShipmentId
    productRetoure_shipmentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductMutationVariables,
  APITypes.UpdateProductMutation
>;
export const deleteProduct = /* GraphQL */ `mutation DeleteProduct(
  $input: DeleteProductInput!
  $condition: ModelProductConditionInput
) {
  deleteProduct(input: $input, condition: $condition) {
    id
    position
    typename
    name
    sku
    product_id
    variation_id
    quantity
    length
    width
    height
    area
    weight
    categories
    variations
    options
    extraOptions {
      name
      value
      imagesp
      price
      __typename
    }
    tasks
    currentTask
    contractor_currentTask
    productContractorId
    contractor {
      id
      number
      name
      billing_rate
      billing_max
      pickupAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      returnAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    status
    contractor_status
    shipping_label_printed
    orderNumber
    order {
      id
      typename
      orderNumber
      date_created
      date_paid
      total
      cart_tax
      total_tax
      discount_total
      discount_tax
      shipping_total
      shipping_tax
      fees {
        name
        amount
        total
        total_tax
        __typename
      }
      weight
      customer {
        first_name
        last_name
        dhl_postNumber
        dhl_lockerId
        street
        house_number
        zip
        city
        country
        email
        phone
        company
        add
        __typename
      }
      shipping_method
      delivery_date
      status
      state
      source
      in_progressAt
      finishedAt
      updatedAt
      createdAt
      retoure_shipping_costs
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productOrderId
    backoffice_note
    total_before_discount
    total_net_price
    total_tax
    nipsild_fixed_costs
    delivery_time
    delivery_date
    shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    tour {
      id
      typename
      date
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      deliveryNotificationSentTo
      info
      driver
      calculated_shipping_costs
      createdAt
      updatedAt
      __typename
    }
    declinedTourDates
    billed_amount
    customer_request_approved
    in_progressAt
    ready_to_packAt
    finishedAt
    updatedAt
    billedAt
    recievedReturnAt
    reklaOrderNumber
    retoure_type
    retoure_reason
    retoure_manufacturing_fee
    retoure_refund_amount
    retoure_refund_contractors_share
    retoure_shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    createdAt
    orderProductsId
    tourProductsId
    productShipmentId
    productRetoure_shipmentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductMutationVariables,
  APITypes.DeleteProductMutation
>;
export const createShipment = /* GraphQL */ `mutation CreateShipment(
  $input: CreateShipmentInput!
  $condition: ModelShipmentConditionInput
) {
  createShipment(input: $input, condition: $condition) {
    id
    typename
    length
    width
    height
    gurtMass
    weight
    serviceProvider
    costs
    calculatedCosts
    shippedAt
    edited
    parcelNumber
    trackID
    suggest_splitting
    overrule_splitting_user
    labelData {
      base64
      format
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateShipmentMutationVariables,
  APITypes.CreateShipmentMutation
>;
export const updateShipment = /* GraphQL */ `mutation UpdateShipment(
  $input: UpdateShipmentInput!
  $condition: ModelShipmentConditionInput
) {
  updateShipment(input: $input, condition: $condition) {
    id
    typename
    length
    width
    height
    gurtMass
    weight
    serviceProvider
    costs
    calculatedCosts
    shippedAt
    edited
    parcelNumber
    trackID
    suggest_splitting
    overrule_splitting_user
    labelData {
      base64
      format
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShipmentMutationVariables,
  APITypes.UpdateShipmentMutation
>;
export const deleteShipment = /* GraphQL */ `mutation DeleteShipment(
  $input: DeleteShipmentInput!
  $condition: ModelShipmentConditionInput
) {
  deleteShipment(input: $input, condition: $condition) {
    id
    typename
    length
    width
    height
    gurtMass
    weight
    serviceProvider
    costs
    calculatedCosts
    shippedAt
    edited
    parcelNumber
    trackID
    suggest_splitting
    overrule_splitting_user
    labelData {
      base64
      format
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteShipmentMutationVariables,
  APITypes.DeleteShipmentMutation
>;
export const createTour = /* GraphQL */ `mutation CreateTour(
  $input: CreateTourInput!
  $condition: ModelTourConditionInput
) {
  createTour(input: $input, condition: $condition) {
    id
    typename
    date
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    deliveryNotificationSentTo
    info
    driver
    calculated_shipping_costs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTourMutationVariables,
  APITypes.CreateTourMutation
>;
export const updateTour = /* GraphQL */ `mutation UpdateTour(
  $input: UpdateTourInput!
  $condition: ModelTourConditionInput
) {
  updateTour(input: $input, condition: $condition) {
    id
    typename
    date
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    deliveryNotificationSentTo
    info
    driver
    calculated_shipping_costs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTourMutationVariables,
  APITypes.UpdateTourMutation
>;
export const deleteTour = /* GraphQL */ `mutation DeleteTour(
  $input: DeleteTourInput!
  $condition: ModelTourConditionInput
) {
  deleteTour(input: $input, condition: $condition) {
    id
    typename
    date
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    deliveryNotificationSentTo
    info
    driver
    calculated_shipping_costs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTourMutationVariables,
  APITypes.DeleteTourMutation
>;
export const createContractor = /* GraphQL */ `mutation CreateContractor(
  $input: CreateContractorInput!
  $condition: ModelContractorConditionInput
) {
  createContractor(input: $input, condition: $condition) {
    id
    number
    name
    billing_rate
    billing_max
    pickupAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    returnAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContractorMutationVariables,
  APITypes.CreateContractorMutation
>;
export const updateContractor = /* GraphQL */ `mutation UpdateContractor(
  $input: UpdateContractorInput!
  $condition: ModelContractorConditionInput
) {
  updateContractor(input: $input, condition: $condition) {
    id
    number
    name
    billing_rate
    billing_max
    pickupAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    returnAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContractorMutationVariables,
  APITypes.UpdateContractorMutation
>;
export const deleteContractor = /* GraphQL */ `mutation DeleteContractor(
  $input: DeleteContractorInput!
  $condition: ModelContractorConditionInput
) {
  deleteContractor(input: $input, condition: $condition) {
    id
    number
    name
    billing_rate
    billing_max
    pickupAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    returnAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContractorMutationVariables,
  APITypes.DeleteContractorMutation
>;
