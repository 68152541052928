import { calculateDeliveryDate, getDeliveryTimeInDays } from '.';
import { Fee } from '../API';

export interface FileInputLine {
    Auftragsnummer: string;
    Auftragseingang: string;
    Bezahlt_am?: string;
    Gesamtsumme: string;
    MwSt_Gesamt: string;
    MwSt_Gesamtwarenwert?: string;
    Rabatt?: string;
    MwSt_Rabatt?: string;
    Nettoversandkosten: string;
    MwSt_Versandkosten: string;
    Gebühren?: [Fee];
    Vorname: string;
    Nachname: string;
    Straße: string;
    Packstation: string;
    Postnummer: string;
    Hausnummer: string;
    PLZ: string;
    Ort: string;
    Land: string;
    Telefonnummer?: string;
    E_Mail?: string;
    Firma?: string;
    Adresszusatz?: string;
    Versandmethode?: string;
    Pos: string;
    Produktname: string;
    Artikelnummer: string;
    Produkt_ID?: string;
    Menge: string;
    Länge?: string;
    Breite?: string;
    Höhe?: string;
    Gewicht: string;
    Kategorien?: string;
    Variationen?: string;
    Aufgaben?: string;
    Fertiger?: string;
    Gesamtsumme_vor_Rabatt?: string;
    Nettopreis: string;
    MwSt: string;
    Lieferzeit?: string;
    Lieferdatum?: string;
    Hinweis?: string;
    Quelle: string
}

export const orderImportFields = [
    {
        name: 'Auftragsnummer',
        mandatory: true,
    },
    {
        name: 'Quelle',
        mandatory: true,
    },
    {
        name: 'Auftragseingang',
        mandatory: true,
    },
    {
        name: 'Bezahlt_am',
        mandatory: false,
    },
    {
        name: 'Gesamtsumme',
        mandatory: true,
    },
    {
        name: 'MwSt_Gesamt',
        mandatory: true,
    },
    {
        name: 'MwSt_Gesamtwarenwert',
        mandatory: false,
    },
    {
        name: 'Rabatt',
        mandatory: false,
    },
    {
        name: 'MwSt_Rabatt',
        mandatory: false,
    },
    {
        name: 'Versandmethode',
        mandatory: false,
    },
    {
        name: 'Nettoversandkosten',
        mandatory: true,
    },
    {
        name: 'MwSt_Versandkosten',
        mandatory: true,
    },
    {
        name: 'Gebühren',
        mandatory: false,
    },
    {
        name: 'Vorname',
        mandatory: true,
    },
    {
        name: 'Nachname',
        mandatory: true,
    },
    {
        name: 'Straße',
        mandatory: false,
    },
    {
        name: 'Hausnummer',
        mandatory: false,
    },
    {
        name: 'Packstation',
        mandatory: false,
    },
    {
        name: 'Postnummer',
        mandatory: false,
    },
    {
        name: 'PLZ',
        mandatory: true,
    },
    {
        name: 'Ort',
        mandatory: true,
    },
    {
        name: 'Land',
        mandatory: true,
    },
    {
        name: 'Telefonnummer',
        mandatory: false,
    },
    {
        name: 'E_Mail',
        mandatory: true,
    },
    {
        name: 'Firma',
        mandatory: false,
    },
    {
        name: 'Adresszusatz',
        mandatory: false,
    },
    {
        name: 'Pos',
        mandatory: true,
    },
    {
        name: 'Produktname',
        mandatory: true,
    },
    {
        name: 'Artikelnummer',
        mandatory: false,
    },
    {
        name: 'Produkt_ID',
        mandatory: false,
    },
    {
        name: 'Menge',
        mandatory: true,
    },
    {
        name: 'Länge',
        mandatory: false,
    },
    {
        name: 'Breite',
        mandatory: false,
    },
    {
        name: 'Höhe',
        mandatory: false,
    },
    {
        name: 'Gewicht',
        mandatory: true,
    },
    {
        name: 'Variationen',
        mandatory: false,
    },
    {
        name: 'Kategorien',
        mandatory: false,
    },
    {
        name: 'Aufgaben',
        mandatory: false,
    },
    {
        name: 'Fertiger',
        mandatory: true,
    },
    {
        name: 'Gesamtsumme_vor_Rabatt',
        mandatory: false,
    },
    {
        name: 'Nettopreis',
        mandatory: true,
    },
    {
        name: 'MwSt',
        mandatory: true,
    },
    {
        name: 'Lieferzeit',
        mandatory: false,
    },
    {
        name: 'Lieferdatum',
        mandatory: false,
    },
    {
        name: 'Hinweis',
        mandatory: false,
    },
]

const mandatoryFields = orderImportFields.filter(field => field.mandatory).map(field => field.name)

export const convertCsvToJson = (csv: any) => {
    const lines = csv.split('\n');
    // remove \r (return) from each line
    for (let i = 0; i < lines.length; i++) {
        lines[i] = lines[i].replace('\r', '');
    }
    const headers = lines[0].includes(';') ? lines[0].split(';') : lines[0].split(',');
    const data = [] as FileInputLine[];
    let error = '';

    for (let i = 1; i < lines.length; i++) {
        const line = lines[i];
        if (line.trim() === '') continue;

        const values = line.includes(';') ? line.split(';') : line.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/); // for comma seperated csv this splits the line by comma but only if the comma is not within double quotes

        const entry = {} as FileInputLine;

        for (let j = 0; j < headers.length; j++) {
            // remove double quotes and * from headers
            const header = headers[j].replace(/"/g, '').replace(/\*/g, '').trim();
            const value = values[j].replace(/"/g, '').trim();
            // @ts-ignore
            entry[header] = value;
        }
        let missingFields: string[] = []
        mandatoryFields.forEach(field => {
            // @ts-ignore
            if (!entry[field]) {
                missingFields.push(field)
            }
        })
        if (!entry['Straße'] && !entry['Packstation']) {
            missingFields.push('Straße oder Packstation')
        }
        if (entry['Straße'] && !entry['Hausnummer']) {
            missingFields.push('Hausnummer')
        }
        if (!entry['Lieferzeit'] && !entry['Lieferdatum']) {
            missingFields.push('Lieferzeit oder Lieferdatum')
        }
        // @ts-ignore
        if (missingFields.length > 0) {
            error = error + `In Zeile ${ i } fehlen folgende Daten: ${ missingFields } \n`
            continue
        }
        data.push(entry);
    }
    return { data, error };
};

export function convertGermanDate(date: string) {
    if (!date.includes('.')) return date
    // convert the german date format to an iso string, if month or day has only one digit, add a 0 in front
    return date.split('.')[2] + '-' + (date.split('.')[1].length === 1 ? '0' + date.split('.')[1] : date.split('.')[1]) + '-' + (date.split('.')[0].length === 1 ? '0' + date.split('.')[0] : date.split('.')[0])
}

export function getdeliveryDate(inputLine: FileInputLine) {
    if (inputLine.Lieferdatum) {
        const isoDate = convertGermanDate(inputLine.Lieferdatum)
        return new Date(isoDate).toISOString()
    } else if (inputLine.Lieferzeit) {
        const deliveryTime = getDeliveryTimeInDays(inputLine.Lieferzeit)
        const date_paid = inputLine.Bezahlt_am ? inputLine.Bezahlt_am : inputLine.Auftragseingang
        const isoDate = convertGermanDate(date_paid)
        const deliveryDate = calculateDeliveryDate(isoDate, deliveryTime, inputLine.Versandmethode)
        return deliveryDate
    }
    return new Date().toISOString()
}